import React from "react";
import "./modal.css"; // Import the CSS file for styles
import { Button } from "./button";

const Modal = ({ isOpen, onClose, title, children, customStyles }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal
        style={customStyles}
      >
        {title && <h4 className="modal-title">{title}</h4>}
        <Button className="modal-close" onClick={onClose}>
          &times;
        </Button>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;

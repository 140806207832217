import React from "react";
import { range } from "../utils/array";

export const TOP_SCORES = "top-scores";
export const NUM_TOP_SCORES_TO_STORE = 5;
export const NUM_TOP_SCORES_TO_DISPLAY = 3;
export const getHighScores = (key) => {
  const storedScores = localStorage.getItem(TOP_SCORES + key);
  if (!storedScores) {
    return getEmptyScoresArray();
  } else {
    return JSON.parse(storedScores);
  }
};
export const updateStoredHighScores = (scoreArr, key) => {
  localStorage.setItem(TOP_SCORES + key, JSON.stringify(scoreArr));
};

export const getEmptyScoresArray = () => {
  const scoresArr = [];

  for (let i = 0; i < NUM_TOP_SCORES_TO_STORE; i++) {
    scoresArr.push(0);
  }

  return scoresArr;
};

export const deleteHighScores = () => {
  localStorage.removeItem(TOP_SCORES);
};

export const getNewScores = (newScore, oldScores) => {
  let newScores = [];
  let newScoreAdded = false;

  for (let i = 0; i < oldScores.length; i++) {
    const storedScore = oldScores[i];

    if (newScore > storedScore && !newScoreAdded) {
      newScores.push(newScore);
      newScoreAdded = true;
    }
    newScores.push(storedScore);
  }

  return newScores.slice(0, NUM_TOP_SCORES_TO_STORE);
};

const medals = ["🥇", "🥈", "🥉"];

export const HighScoreList = ({ highScores, indexToHighlight }) => {
  const rowStyle = {
    fontSize: 24,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    opacity: 0.9,
  };
  return (
    <div className="card color-1" style={{ padding: "8px", gap: 8 }}>
      {/* <div style={{ display: "flex", gap: 16, justifyContent: "center" }}> */}
      {/* <p style={{ fontSize: 16 }}>High scores</p> */}
      {/* <div className="button color-1" onClick={deleteHighScores}>
          Reset
        </div> */}
      {/* </div> */}
      {range(NUM_TOP_SCORES_TO_DISPLAY).map((i) => (
        <p
          style={rowStyle}
          className={indexToHighlight === i ? "green-text" : undefined}
          key={i}
        >
          {medals[i]} {highScores[i]}
        </p>
      ))}
    </div>
  );
};

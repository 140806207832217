import React, { useCallback, useEffect, useState } from "react";
import {
  GAME_TYPE_DIAGONALS,
  GAME_TYPE_LEGAL_MOVES,
  GAME_TYPE_LIGHT_DARK_SQUARE,
  GAME_TYPE_NUM_MOVES,
} from "./game";
import { randomChoiceFromArray } from "../utils/array";
import {
  files,
  getIllegalMoves,
  getLegalMoves,
  getNonDiagonalSquares,
  getReachableSquares,
  getSameDiagonalSquares,
  getUnreachableSquares,
  pieces,
  ranks,
} from "../data/chess";
import { Button } from "./button";

export const Question = ({
  onRightAnswer,
  onWrongAnswer,
  numRight,
  numWrong,
  gameType,
}) => {
  const [question, setQuestion] = useState(generateQuestion(gameType));

  const submitAnswer = useCallback(
    (answer) => {
      if (answer.isCorrect) {
        onRightAnswer(question.content);
      } else {
        onWrongAnswer(question.content);
      }
      setQuestion(generateQuestion(gameType, question));
    },
    [gameType, question, onRightAnswer, onWrongAnswer]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      const answerTriggered = question.answers.find(
        (answer) => answer.keyTrigger === event.key
      );

      if (answerTriggered) {
        submitAnswer(answerTriggered);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [question, onRightAnswer, onWrongAnswer, submitAnswer]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 16,
        }}
      >
        {question.questionToRender}
      </div>

      <div style={{ display: "flex", justifyContent: "center", gap: 16 }}>
        {question.answers.map((answer) => (
          <Button
            key={answer.name}
            className="answer-input color-1"
            onClick={() => {
              submitAnswer(answer);
            }}
          >
            {answer.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

const generateQuestion = (gameType, prevQuestion) => {
  let questionCount = 0;
  while (true) {
    const newQuestion = getQuestionForGameType(gameType);

    if (prevQuestion == null || newQuestion.key !== prevQuestion.key) {
      return newQuestion;
    }

    if (questionCount++ > 10) {
      return newQuestion;
    }
  }
};

const getQuestionForGameType = (gameType) => {
  if (gameType === GAME_TYPE_LIGHT_DARK_SQUARE) {
    const file = randomChoiceFromArray(files);
    const rank = randomChoiceFromArray(ranks);

    const content = file + rank;

    const questionContent = (
      <p>
        Is <b>{content}</b> a dark or light square?
      </p>
    );

    const isDark = (files.indexOf(file) + ranks.indexOf(rank)) % 2 === 0;

    const answers = [
      { name: "dark", isCorrect: isDark, keyTrigger: "ArrowLeft" },
      { name: "light", isCorrect: !isDark, keyTrigger: "ArrowRight" },
    ];

    return new GameQuestion(answers, questionContent, `${file}${rank}`);
  } else if (gameType === GAME_TYPE_DIAGONALS) {
    const file = randomChoiceFromArray(files);
    const rank = randomChoiceFromArray(ranks);

    const isCorrect = randomChoiceFromArray([true, false]);
    const answers = [
      { name: "no", isCorrect: !isCorrect, keyTrigger: "ArrowLeft" },
      { name: "yes", isCorrect: isCorrect, keyTrigger: "ArrowRight" },
    ];

    const sameDiagonalSquares = getSameDiagonalSquares(file, rank);
    const diffDiagonalSquares = getNonDiagonalSquares(file, rank);

    const square1 = file + rank;
    const square2 = isCorrect
      ? randomChoiceFromArray(sameDiagonalSquares).join("")
      : randomChoiceFromArray(diffDiagonalSquares).join("");

    const questionToRender = (
      <div>
        <p>
          Are <b>{square1}</b> and <b>{square2}</b> on the same diagonal?
        </p>
      </div>
    );

    return new GameQuestion(answers, questionToRender, `${square1}${square2}`);
  } else if (gameType === GAME_TYPE_LEGAL_MOVES) {
    const file = randomChoiceFromArray(files);
    const rank = randomChoiceFromArray(ranks);

    const piece = randomChoiceFromArray(pieces);

    const isCorrect = randomChoiceFromArray([true, false]);
    const answers = [
      { name: "no", isCorrect: !isCorrect, keyTrigger: "ArrowLeft" },
      { name: "yes", isCorrect: isCorrect, keyTrigger: "ArrowRight" },
    ];

    const legalSquares = getLegalMoves(file, rank, piece);
    const illegalSquares = getIllegalMoves(file, rank, piece);

    const square1 = file + rank;
    const square2 = isCorrect
      ? randomChoiceFromArray(legalSquares)
      : randomChoiceFromArray(illegalSquares);

    const questionToRender = (
      <div>
        <p>
          Is moving {piece === "king" || piece === "queen" ? "the" : "a"}{" "}
          <b>{piece}</b> from <b>{square1}</b> to <b>{square2}</b> legal?
        </p>
      </div>
    );

    return new GameQuestion(
      answers,
      questionToRender,
      `${piece}${square1}${square2}`
    );
  } else if (gameType === GAME_TYPE_NUM_MOVES) {
    const file = randomChoiceFromArray(files);
    const rank = randomChoiceFromArray(ranks);

    const piece = "knight";

    const isCorrect = randomChoiceFromArray([true, false]);

    const correctNum = randomChoiceFromArray([2, 3, 4]);

    const answers = [
      { name: "no", isCorrect: !isCorrect, keyTrigger: "ArrowLeft" },
      { name: "yes", isCorrect: isCorrect, keyTrigger: "ArrowRight" },
    ];

    const reachableSquares = Array.from(
      getReachableSquares(file, rank, piece, correctNum)
    );
    const unreachableSquares = getUnreachableSquares(
      file,
      rank,
      piece,
      correctNum
    );

    // console.log(piece, file, rank, correctNum);
    // console.log(reachableSquares);
    // console.log(unreachableSquares);

    const square1 = file + rank;
    const square2 = isCorrect
      ? randomChoiceFromArray(reachableSquares)
      : randomChoiceFromArray(unreachableSquares);

    const questionToRender = (
      <div>
        <p>
          Can {piece === "king" || piece === "queen" ? "the" : "a"}{" "}
          <b>{piece}</b> get from <b>{square1}</b> to <b>{square2}</b> within{" "}
          <b>{correctNum}</b> moves?
        </p>
      </div>
    );

    return new GameQuestion(
      answers,
      questionToRender,
      `${piece}${square1}${square2}${correctNum}`
    );
  } else {
    throw new Error("unrecognized game type");
  }
};

class GameQuestion {
  answers;
  questionToRender;
  key;

  constructor(answers, questionToRender, key) {
    this.answers = answers;
    this.questionToRender = questionToRender;
    this.key = key;
  }
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Game,
  GAME_TYPE_DIAGONALS,
  GAME_TYPE_LIGHT_DARK_SQUARE,
  GAME_TYPE_LEGAL_MOVES,
  GAME_TYPE_NUM_MOVES,
} from "./game";
import { StartGamePrompt } from "./start-game-prompt";
import {
  HighScoreList,
  NUM_TOP_SCORES_TO_DISPLAY,
  getEmptyScoresArray,
  getHighScores,
  getNewScores,
  updateStoredHighScores,
} from "./high-score-list";
import { useIsMobile } from "../utils/useIsMobile";
import Modal from "./modal";
import { Button } from "./button";

const ACTIVE_GAME_ID = "active-game-id";

const HELP_MODAL = "help-modal";
const GAME_OVER_MODAL = "game-over-modal";

const getCurrActiveGameId = () => {
  const activeGameId = localStorage.getItem(ACTIVE_GAME_ID);
  if (!activeGameId) {
    localStorage.setItem(ACTIVE_GAME_ID, 0);
    return 0;
  } else {
    return parseInt(activeGameId);
  }
};

const updateGameId = () => {
  const activeGameId = localStorage.getItem(ACTIVE_GAME_ID);
  if (!activeGameId) {
    localStorage.setItem(ACTIVE_GAME_ID, 0);
  } else {
    localStorage.setItem(ACTIVE_GAME_ID, parseInt(activeGameId) + 1);
  }
};

export const Home = () => {
  const [lightOrDarkHighScores, setLightOrDarkHighScores] = useState(
    getHighScores(GAME_TYPE_LIGHT_DARK_SQUARE)
  );
  const [diagonalHighScores, setDiagonalHighScores] = useState(
    getHighScores(GAME_TYPE_DIAGONALS)
  );
  const [legalMovesHighScores, setLegalMovesHighScores] = useState(
    getHighScores(GAME_TYPE_LEGAL_MOVES)
  );
  const [numMovesHighScores, setNumMovesHighScores] = useState(
    getHighScores(GAME_TYPE_NUM_MOVES)
  );

  const [gameOverModalState, setGameOverModalState] = useState({
    score: 0,
    highScores: [0, 0, 0],
    gameType: null,
  });

  const getHighScoresByGameType = (gameType) => {
    if (gameType === GAME_TYPE_LIGHT_DARK_SQUARE) {
      return lightOrDarkHighScores;
    } else if (gameType === GAME_TYPE_DIAGONALS) {
      return diagonalHighScores;
    } else if (gameType === GAME_TYPE_LEGAL_MOVES) {
      return legalMovesHighScores;
    } else if (gameType === GAME_TYPE_NUM_MOVES) {
      return numMovesHighScores;
    }
  };

  const [modalState, setModalState] = useState(null);

  const openHelpModal = useCallback(() => setModalState(HELP_MODAL), []);
  const closeModal = useCallback(() => setModalState(null), []);

  const isMobile = useIsMobile();

  const [gameType, setGameType] = useState(null);
  const isGameActive = gameType != null;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto", // 'auto' for immediate scroll, 'smooth' for smooth scroll
    });
  }, [gameType]);

  const updateHighScores = (score, gameId, gameType) => {
    if (gameId !== getCurrActiveGameId()) {
      return;
    } else {
      updateGameId();
    }

    const setScoresState = (scores) => {
      if (gameType === GAME_TYPE_LIGHT_DARK_SQUARE) {
        setLightOrDarkHighScores(scores);
      } else if (gameType === GAME_TYPE_DIAGONALS) {
        setDiagonalHighScores(scores);
      } else if (gameType === GAME_TYPE_LEGAL_MOVES) {
        setLegalMovesHighScores(scores);
      } else if (gameType === GAME_TYPE_NUM_MOVES) {
        setNumMovesHighScores(scores);
      }
      updateStoredHighScores(scores, gameType);
    };
    let storedScores = getHighScores(gameType);
    if (!storedScores) {
      const emptyScoresArray = getEmptyScoresArray();
      emptyScoresArray[0] = score;
      setScoresState(emptyScoresArray);
    } else {
      const newScores = getNewScores(score, storedScores);
      setScoresState(newScores);
    }
  };

  const content = (() => {
    if (isGameActive) {
      return (
        <Game
          onGameEnd={(numRight, gameId) => {
            setGameOverModalState({
              score: numRight,
              highScores: getHighScoresByGameType(gameType),
              gameType: gameType,
            });
            setModalState(GAME_OVER_MODAL);

            updateHighScores(numRight, gameId, gameType);
            setGameType(null);
          }}
          gameId={getCurrActiveGameId()}
          isMobile={isMobile}
          gameType={gameType}
        />
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
            alignItems: "center",
          }}
        >
          <h1>Gangsta Chess</h1>
          <h6 style={{ width: 300, paddingBottom: 8 }}>
            Train your visualization and boost your elo - results guaranteed 😎
          </h6>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 24,
            }}
          >
            <GameTile
              highScores={lightOrDarkHighScores}
              setIsGameActive={() => setGameType(GAME_TYPE_LIGHT_DARK_SQUARE)}
              name="Light vs Dark 🟡"
            />
            <GameTile
              highScores={diagonalHighScores}
              setIsGameActive={() => setGameType(GAME_TYPE_DIAGONALS)}
              name="Diagonals 🟠"
            />
            <GameTile
              highScores={legalMovesHighScores}
              setIsGameActive={() => setGameType(GAME_TYPE_LEGAL_MOVES)}
              name="Legal Moves 🔴"
            />
            <GameTile
              highScores={numMovesHighScores}
              setIsGameActive={() => setGameType(GAME_TYPE_NUM_MOVES)}
              name="Count Moves 💀"
            />
          </div>
          <h6
            className="clickable"
            onClick={openHelpModal} // TOOD: open dialog explaining why visualization will help your elo
            style={{ padding: 8 }}
          >
            New to Gangsta life?
          </h6>
        </div>
      );
    }
  })();

  return (
    <div style={{ padding: 32 }}>
      {content}
      <HelpModal modalState={modalState} closeModal={closeModal} />
      <GameOverModal
        modalState={modalState}
        closeModal={closeModal}
        onTryAgain={() => setGameType(gameOverModalState.gameType)}
        highScores={gameOverModalState.highScores}
        score={gameOverModalState.score}
      />
    </div>
  );
};

const HelpModal = ({ modalState, closeModal }) => {
  return (
    <Modal
      isOpen={modalState === HELP_MODAL}
      onClose={closeModal}
      title="Welcome!"
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
        <p>
          Here you will find addictive chess mini games to improve your vision
          and boost your elo 😎
        </p>
        <b>Train like the masters to become one!</b>
        <p>
          What do all strong chess masters have in common? <br />
        </p>
        <b>They can play blind-folded!</b>
        <p>
          Visualization is an essential skill that will accelerate your mastery
          of the game.
        </p>
        <Button style={{ padding: 16, margin: 16 }} onClick={closeModal}>
          Start training!
        </Button>
      </div>
    </Modal>
  );
};

const GameOverModal = ({
  modalState,
  closeModal,
  highScores,
  score,
  onTryAgain,
}) => {
  const newHighScores = useMemo(() => {
    return getNewScores(score, highScores);
  }, [highScores, score]);

  const newHighScoreIdx = useMemo(() => {
    return newHighScores.slice(0, NUM_TOP_SCORES_TO_DISPLAY).indexOf(score);
  }, [newHighScores, score]);

  const isHighScore = useMemo(() => {
    return newHighScoreIdx !== -1 && score !== 0;
  }, [score, newHighScoreIdx]);

  return (
    <Modal
      isOpen={modalState === GAME_OVER_MODAL}
      onClose={closeModal}
      title="Congrats!"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          alignItems: "center",
        }}
      >
        {isHighScore ? (
          <p>
            You earned a{" "}
            <b>
              {newHighScoreIdx === 0
                ? "gold"
                : newHighScoreIdx === 1
                ? "silver"
                : "bronze"}
            </b>{" "}
            medal with a score of {score}! 😎
          </p>
        ) : (
          <p>You got {score} right! You are a gangsta 😎</p>
        )}
        {
          <HighScoreList
            highScores={newHighScores}
            indexToHighlight={
              isHighScore ? newHighScores.indexOf(score) : undefined
            }
          />
        }

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16,
            alignSelf: "stretch",
          }}
        >
          <Button
            style={{ padding: 16, flex: 1 }}
            onClick={closeModal}
            isSecondary
          >
            New game
          </Button>
          <Button
            style={{ padding: 16, flex: 1 }}
            onClick={() => {
              closeModal();
              onTryAgain();
            }}
          >
            Try again!
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const GameTile = ({ highScores, setIsGameActive, name }) => {
  return (
    <div
      className="big-box"
      style={{ flexDirection: "column", gap: 16, padding: 32 }}
    >
      <h4>{name}</h4>
      <HighScoreList highScores={highScores} />
      <StartGamePrompt
        startGameFn={() => {
          setIsGameActive(true);
        }}
      />
    </div>
  );
};

import React, { useState, useEffect } from "react";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

const TOTAL_SECONDS = 30;

export const Timer = ({ timeUpFn }) => {
  const [secondsPassed, setSecondsPassed] = useState(0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setSecondsPassed((prev) => prev + 1);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  useEffect(() => {
    if (secondsPassed === TOTAL_SECONDS) {
      timeUpFn();
    }
  }, [secondsPassed, timeUpFn]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AccessAlarmIcon />
      <div style={{ width: 70 }}>
        {formatTime(TOTAL_SECONDS - secondsPassed)}
      </div>
    </div>
  );
};

const formatTime = (secondsLeft) => {
  const seconds = secondsLeft % 60;
  const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${Math.floor(secondsLeft / 60)}:${secondsStr}`;
};

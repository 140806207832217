export const ranks = ["1", "2", "3", "4", "5", "6", "7", "8"];
export const files = ["a", "b", "c", "d", "e", "f", "g", "h"];
export const pieces = ["king", "queen", "rook", "bishop", "knight", "pawn"];

export const squares = (() => {
  const allSquares = [];
  for (const f of files) {
    for (const r of ranks) {
      allSquares.push(`${f}${r}`);
    }
  }
  return allSquares;
})();

export const getSameDiagonalSquares = (file, rank) => {
  // Convert file and rank to numerical indices
  const fileIndex = files.indexOf(file);
  const rankIndex = ranks.indexOf(rank);

  // Calculate the constants for the diagonals
  const sum = fileIndex + rankIndex;
  const diff = fileIndex - rankIndex;

  const result = [];

  // Iterate over all squares on the board
  for (let f = 0; f < files.length; f++) {
    for (let r = 0; r < ranks.length; r++) {
      // Skip the original square
      if (f === fileIndex && r === rankIndex) continue;

      // Check if the square is on the same diagonal
      if (f + r === sum || f - r === diff) {
        result.push([files[f], ranks[r]]);
      }
    }
  }

  return result;
};

export const getNonDiagonalSquares = (file, rank) => {
  // Convert file and rank to numerical indices
  const fileIndex = files.indexOf(file);
  const rankIndex = ranks.indexOf(rank);

  // Calculate the constants for the diagonals
  const sum = fileIndex + rankIndex;
  const diff = fileIndex - rankIndex;

  const result = [];

  // Iterate over all squares on the board
  for (let f = 0; f < files.length; f++) {
    for (let r = 0; r < ranks.length; r++) {
      // Skip the original square
      if (f === fileIndex && r === rankIndex) continue;

      // Only include squares that are NOT on the same diagonal
      if (f + r !== sum && f - r !== diff) {
        result.push([files[f], ranks[r]]);
      }
    }
  }

  return result;
};

export const getLegalMoves = (file, rank, piece) => {
  const legalMoves = [];

  const fileIndex = files.indexOf(file);
  const rankIndex = ranks.indexOf(rank);

  switch (piece) {
    case "king":
      // King can move one square in any direction
      for (let x = -1; x <= 1; x++) {
        for (let y = -1; y <= 1; y++) {
          if (x === 0 && y === 0) continue; // skip the current position
          const newFileIndex = fileIndex + x;
          const newRankIndex = rankIndex + y;
          if (
            newFileIndex >= 0 &&
            newFileIndex < files.length &&
            newRankIndex >= 0 &&
            newRankIndex < ranks.length
          ) {
            legalMoves.push(`${files[newFileIndex]}${ranks[newRankIndex]}`);
          }
        }
      }
      break;

    case "queen":
      // Queen can move in any direction any number of squares
      for (let x = -1; x <= 1; x++) {
        for (let y = -1; y <= 1; y++) {
          if (x === 0 && y === 0) continue; // skip the current position
          for (let step = 1; step < 8; step++) {
            const newFileIndex = fileIndex + x * step;
            const newRankIndex = rankIndex + y * step;
            if (
              newFileIndex >= 0 &&
              newFileIndex < files.length &&
              newRankIndex >= 0 &&
              newRankIndex < ranks.length
            ) {
              legalMoves.push(`${files[newFileIndex]}${ranks[newRankIndex]}`);
            } else {
              break; // Out of bounds
            }
          }
        }
      }
      break;

    case "rook":
      // Rook can move horizontally or vertically any number of squares
      for (let direction of [-1, 1]) {
        for (let step = 1; step < 8; step++) {
          const newFileIndex = fileIndex + direction * step;
          if (newFileIndex >= 0 && newFileIndex < files.length) {
            legalMoves.push(`${files[newFileIndex]}${rank}`); // Horizontal
          } else {
            break; // Out of bounds
          }
        }
      }
      for (let direction of [-1, 1]) {
        for (let step = 1; step < 8; step++) {
          const newRankIndex = rankIndex + direction * step;
          if (newRankIndex >= 0 && newRankIndex < ranks.length) {
            legalMoves.push(`${file}${ranks[newRankIndex]}`); // Vertical
          } else {
            break; // Out of bounds
          }
        }
      }
      break;

    case "bishop":
      // Bishop can move diagonally any number of squares
      for (let x = -1; x <= 1; x += 2) {
        for (let y = -1; y <= 1; y += 2) {
          for (let step = 1; step < 8; step++) {
            const newFileIndex = fileIndex + x * step;
            const newRankIndex = rankIndex + y * step;
            if (
              newFileIndex >= 0 &&
              newFileIndex < files.length &&
              newRankIndex >= 0 &&
              newRankIndex < ranks.length
            ) {
              legalMoves.push(`${files[newFileIndex]}${ranks[newRankIndex]}`);
            } else {
              break; // Out of bounds
            }
          }
        }
      }
      break;

    case "knight":
      // Knight moves in an L-shape
      const knightMoves = [
        [2, 1],
        [2, -1],
        [-2, 1],
        [-2, -1],
        [1, 2],
        [1, -2],
        [-1, 2],
        [-1, -2],
      ];
      for (let [x, y] of knightMoves) {
        const newFileIndex = fileIndex + x;
        const newRankIndex = rankIndex + y;
        if (
          newFileIndex >= 0 &&
          newFileIndex < files.length &&
          newRankIndex >= 0 &&
          newRankIndex < ranks.length
        ) {
          legalMoves.push(`${files[newFileIndex]}${ranks[newRankIndex]}`);
        }
      }
      break;

    case "pawn":
      // Pawns move forward one square but capture diagonally
      const direction = rankIndex === 1 ? 1 : -1; // assuming pawns only move up
      if (rankIndex + direction >= 0 && rankIndex + direction < ranks.length) {
        legalMoves.push(`${file}${ranks[rankIndex + direction]}`); // Move forward
        // Capture diagonally
        if (fileIndex > 0) {
          legalMoves.push(
            `${files[fileIndex - 1]}${ranks[rankIndex + direction]}`
          ); // Capture left
        }
        if (fileIndex < files.length - 1) {
          legalMoves.push(
            `${files[fileIndex + 1]}${ranks[rankIndex + direction]}`
          ); // Capture right
        }
      }
      break;

    default:
      break;
  }

  return legalMoves;
};

export const getIllegalMoves = (file, rank, piece) => {
  // Get legal moves using the existing function
  const legalMoves = getLegalMoves(file, rank, piece);

  // Get illegal moves by filtering out legal moves and the current square
  const illegalMoves = squares.filter((square) => !legalMoves.includes(square));

  return illegalMoves;
};

const memo = {};

export const getReachableSquares = (file, rank, piece, moves) => {
  // Create a unique key for memoization
  const memoKey = `${file}${rank}-${piece}-${moves}`;
  if (memo[memoKey]) return memo[memoKey];

  // Base case: if no moves left, return current position
  if (moves === 0) {
    return new Set([`${file}${rank}`]);
  }

  const reachableSquares = new Set();

  // Get legal moves from the current position
  const legalMoves = getLegalMoves(file, rank, piece);

  for (const move of legalMoves) {
    const [nextFile, nextRank] = [move[0], move[1]];
    const nextReachableSquares = getReachableSquares(
      nextFile,
      nextRank,
      piece,
      moves - 1
    );

    // Add the squares reachable in the next move to the current set
    nextReachableSquares.forEach((square) => reachableSquares.add(square));
  }

  // Store the result in the memo object for future reference
  memo[memoKey] = reachableSquares;
  return reachableSquares;
};

export const getUnreachableSquares = (file, rank, piece, moves) => {
  // Get reachable squares using the existing function
  const reachableSquares = getReachableSquares(file, rank, piece, moves);

  // Calculate unreachable squares by filtering out reachable ones
  const unreachableSquares = squares.filter(
    (square) => !reachableSquares.has(square)
  );

  return unreachableSquares;
};

export const Button = ({
  children,
  style,
  onClick,
  className,
  isSecondary,
}) => {
  return (
    <button
      style={{ ...style, borderWidth: isSecondary ? 0 : undefined }}
      onClick={() => {
        onClick();
      }}
      className={className}
    >
      {children}
    </button>
  );
};

import React from "react";
import { Button } from "./button";

export const StartGamePrompt = ({ startGameFn }) => {
  return (
    <Button style={{ color: "white" }} onClick={startGameFn}>
      New game
    </Button>
  );
};

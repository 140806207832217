import React, { useState } from "react";
import { Timer } from "./timer";
import { Question } from "./question";
import SlickText from "./slick-text";

export const GAME_TYPE_LIGHT_DARK_SQUARE = "light-dark-square";
export const GAME_TYPE_DIAGONALS = "diagonals";
export const GAME_TYPE_LEGAL_MOVES = "legal-moves";
export const GAME_TYPE_NUM_MOVES = "num-moves";

export const Game = ({ onGameEnd, gameId, isMobile, gameType }) => {
  const [rightAnswers, setRightAnswers] = useState([]);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const numRight = rightAnswers.length;
  const numWrong = wrongAnswers.length;

  return (
    <div
      style={{
        display: "flex",
        gap: 24,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", gap: 16, flexDirection: "row" }}>
        <Timer
          timeUpFn={() => {
            onGameEnd(numRight, gameId);
          }}
        />
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          Score: <SlickText text={numRight} key={numRight} />
        </div>
      </div>
      {isMobile && <Xs wrongAnswers={wrongAnswers} />}
      <Question
        onRightAnswer={(question) =>
          setRightAnswers((prev) => [...prev, question])
        }
        onWrongAnswer={(question) => {
          if (numWrong === 2) {
            onGameEnd(numRight, gameId);
          }
          setWrongAnswers((prev) => [...prev, question]);
        }}
        numRight={numRight}
        numWrong={numWrong}
        gameType={gameType}
      />
      {!isMobile && <Xs wrongAnswers={wrongAnswers} />}

      {!isMobile && (
        <p style={{ fontSize: 14 }}>Use ← or → keys for quick answers</p>
      )}
    </div>
  );
};

const Xs = ({ wrongAnswers }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 16,
        justifyContent: "center",
        fontSize: 18,
        flexWrap: "wrap",
      }}
    >
      <div className={wrongAnswers.length > 0 ? "red-box" : "charcoal-box"}>
        <b className={wrongAnswers.length > 0 ? "red-text" : "charcoal-text"}>
          X
        </b>
      </div>
      <div className={wrongAnswers.length > 1 ? "red-box" : "charcoal-box"}>
        <b className={wrongAnswers.length > 1 ? "red-text" : "charcoal-text"}>
          X
        </b>
      </div>
      <div className={wrongAnswers.length > 2 ? "red-box" : "charcoal-box"}>
        <b className={wrongAnswers.length > 2 ? "red-text" : "charcoal-text"}>
          X
        </b>
      </div>
    </div>
  );
};

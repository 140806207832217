import React from "react";
import { useSpring, animated } from "@react-spring/web";

const SubtleTextAnimation = ({ text }) => {
  const show = true;
  const styles = useSpring({
    opacity: show ? 1 : 0.7,
    transform: show ? "translateX(0)" : "translateX(-10%)",
    from: { opacity: 0.7, transform: "translateX(-10%)" },
    config: { tension: 200, friction: 20 }, // Controls the speed of the animation
  });

  return (
    <animated.h3 style={styles} className="green-text">
      {text}
    </animated.h3>
  );
};

export default SubtleTextAnimation;

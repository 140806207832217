import { useState, useEffect } from "react";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileDevice = /android|iPad|iPhone|iPod/i.test(userAgent);
      setIsMobile(isMobileDevice);
    };

    checkIsMobile();
  }, []);

  return isMobile;
};
